import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import {connect} from 'react-redux';
import {getHydrogenReport} from '../../reducers/reportGoReducer';
import { reduxForm, getFormValues} from 'redux-form/immutable';
import {FormActions} from '../../components';
import {goReportFields} from '../../constants/GoReport.js';
import {FORM_GO_REPORT_HYDROGEN} from '../../constants/formKeys';
import {Row, Button, Spin, Table, Modal} from 'antd';
import {getRole} from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import * as actions from '../../actions/actionCreators/reportGoActionCreators';
import {withRouter} from "react-router-dom";
import BaseGoReport, { calcInitialDates } from './BaseGoReport';

export class HydrogenGoReport extends BaseGoReport(Component) {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    formValues: PropTypesImmutable.map,
    fetchReportGoItemsPage: PropTypes.func.isRequired,
    resetGoHydrogenReport: PropTypes.func.isRequired,
    goReport: PropTypes.object,
    location: PropTypes.object,
  };
  componentWillUnmount() {
    this.props.resetGoHydrogenReport();
  }
  render() {
    const {t, goReport, location} = this.props;
    const {monthFilter} = this.state;

    const {data, page, totalElements, pageSize} = goReport.toJSON();
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <form>
            {this.renderFirstRows(monthFilter, t)}
            <Row gutter={32}>
              <Table
                scroll={{ x: 'auto' }}
                rowKey={goReportFields.ID}
                columns={this.getHydrogenReportColumns(t)}
                dataSource={data}
                bordered
                pagination={{
                  onChange: this.onPageChange,
                  current: page,
                  total: totalElements,
                  pageSize,
                  defaultCurrent: 1
                }}
              />
            </Row>
            <FormActions>
              {this.createAndClearButtons(t, location)}
              <a href={this.getHydrogenGenerateXlsUrl()} target="_blank" rel="noopener noreferrer">
                <Button>
                  {t('goReport.generateXls')}
                </Button>
              </a>
            </FormActions>
          </form>
        </Spin>
        <Modal
          title={t('goReport.reportDetailModalTitle')}
          visible={this.state.detailModalVisible}
          footer={false}
          width="1200px"
          onCancel={this.hideDetailModal}
        >
          {this.renderHydrogenGoReportDetails()}
        </Modal>
      </div>
    );
  }

}

const formValuesSelector = getFormValues(FORM_GO_REPORT_HYDROGEN);

const mapStateToProps = state => ({
  isUserAdmin: isAdmin(getRole(state)),
  initialValues: calcInitialDates(),
  formValues: formValuesSelector(state),
  goReport: getHydrogenReport(state)
});

const mapDispatchToProps = {
  fetchReportGoItemsPage: actions.fetchHydrogenReportGoItemsPage,
  resetGoHydrogenReport: actions.goHydrogenReportResetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_GO_REPORT_HYDROGEN,
    enableReinitialize: true
  })(withRouter(HydrogenGoReport))
);
