import axios from 'axios';
import {compileUrl} from "./asyncHelpers";
import moment from "moment";
import { message } from 'antd';

export const downloadFileByURL = (url, name) => {
  const fileName = name || url;
  const downloadElement = document.createElement('A');
  downloadElement.href = url;
  downloadElement.setAttribute('download', fileName);
  document.body.appendChild(downloadElement);
  downloadElement.click();
};

// Use this over downloadFileByURL if requesting file from server and not
// setting the file blob explicitly
export const downloadFileByURLWithoutDownloadAttribute = (url) => {
  const downloadElement = document.createElement('A');
  downloadElement.href = url;
  document.body.appendChild(downloadElement);
  downloadElement.click();
};

export const downloadFileByPostURL = (
  url,
  params,
  fileName,
  method = 'post'
) => {
  axios
    .request({
      url: `/api/v1${url}`,
      method,
      headers: {
        'content-type': 'application/json',
      },
      responseType: 'blob',
      data: params,
    })
    .then(response => {
      downloadFileByURL(
        window.URL.createObjectURL(new Blob([response.data])),
        fileName
      );
    });
};
export const downloadFileByGetURL = (
  url,
  params,
  fileName,
  method = 'get',
  t = null
) => {
  const paramString = params ? new URLSearchParams(params).toString() : null;
  axios
    .request({
      url: `/api/v1${url}` + (paramString ? '\?' + paramString : ''),
      method,
      headers: {
        'content-type': 'application/json',
      },
      responseType: 'blob',
    })
    .then(response => {
      downloadFileByURL(
        window.URL.createObjectURL(new Blob([response.data])),
        fileName
      );
    })
    .catch(error => {
      if(error.response.status === 404) {
        if (t){
          message.error(t('notFound'))
        }
      }
    });
};

export const downloadTradingAccountFileByGetURL = (
  url,
  params,
  fileName,
  method = 'get'
) => {
  const {transactionDateFrom, transactionDateTo} = params;
  url = compileUrl(url, {
    ...params,
    transactionDateFrom: transactionDateFrom ? moment(transactionDateFrom, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null,
    transactionDateTo: transactionDateTo ? moment(transactionDateTo, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') : null
  });
  axios
    .request({
      url: `/api/v1${url}`,
      method,
      headers: {
        'content-type': 'application/json',
      },
      responseType: 'blob',
      data: params,
    })
    .then(response => {
      downloadFileByURL(
        window.URL.createObjectURL(new Blob([response.data])),
        fileName
      );
    });
};

export const getFileTextContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (error) => {
      console.error(error);
      reject(error);
    };
    reader.readAsText(file, "UTF-8");
  });
};
