import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Spin, Button} from 'antd';
import PropTypes from 'prop-types';
import {getLoadingSelector, getEntityById} from '../../utils/asyncHelpers';
import {downloadFileByURLWithoutDownloadAttribute} from '../../utils/files';
import {meteringPointReviewSchema} from '../../schemas';
import {notificationAdminFields} from '../../constants/notificationAdminFields';
import * as actionCreators from '../../actions/actionCreators/notificationActionCreators';
import {
  getNotificationApprove,
  getNotificationDecline,
} from '../../reducers/notificationActionReducer';
import './NotificationViewAdmin.css';

export class NotificationViewAdmin extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    declineNotification: PropTypes.func.isRequired,
    approveNotification: PropTypes.func.isRequired,
    selectedId: PropTypes.number.isRequired,
    notificationData: PropTypes.object,
  };

  declineNotification = () => {
    const {selectedId, declineNotification} = this.props;
    declineNotification(selectedId);
  };

  approveNotification = () => {
    const {selectedId, approveNotification} = this.props;
    approveNotification(selectedId);
  };

  render() {
    const {isLoading, t, notificationData} = this.props;

    return (
      <Spin spinning={isLoading} className="container-NotificationViewAdmin">
        <div className="container-NotificationViewAdmin__row">
          <span>{t('fieldCompanyName')}: </span>
          <b>
            {notificationData.get(notificationAdminFields.LEGAL_ENTITY_NAME)}
          </b>
        </div>
        <div className="container-NotificationViewAdmin__row">
          <span>{t('fieldPeriod')}: </span>
          <b>{notificationData.get(notificationAdminFields.PERIOD)}</b>
        </div>
        <div className="container-NotificationViewAdmin__row">
          <Button
            icon="download"
            onClick={() =>
              downloadFileByURLWithoutDownloadAttribute(
                notificationData.get(notificationAdminFields.FILE_URL)
              )
            }
          >
            {t('buttonDownloadFile')}
          </Button>
        </div>
        <div className="container-NotificationViewAdmin__footer">
          <Button type="danger" onClick={this.declineNotification}>
            {t('buttonDecline')}
          </Button>
          <Button
            onClick={this.approveNotification}
            className="container-NotificationViewAdmin__footer__accept"
            type="primary"
          >
            {t('buttonAccept')}
          </Button>
        </div>
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getNotificationApprove,
  getNotificationDecline
);

const mapStateToProps = (state, ownProps) => ({
  isLoading: getLoading(state),
  notificationData: getEntityById(
    state,
    meteringPointReviewSchema,
    ownProps.selectedId
  ),
});

const mapDispatchToProps = {
  approveNotification: actionCreators.approveNotification,
  declineNotification: actionCreators.declineNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  NotificationViewAdmin
);
