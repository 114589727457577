import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { Field, getFormValues, reduxForm } from 'redux-form/immutable';
import { sellingAgreementsFilterFields } from '../../constants/formAccountGOSSellingAgreementsFilter';
import { ClassificatorField, YearSelect } from '../../atoms';
import AutoCompleteAccountsRegCodeField from '../../components/AutoCompleteAccountsField/AutoCompleteAccountsRegCodeField';
import { FormActions } from '../../components';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { downloadFileByPostURL } from '../../utils/files';
import { fuelType } from '../../constants/classificators';

const { Panel } = Collapse;

const yearSelect = field => {
  return (
    <Form.Item label={field.label}>
      <YearSelect
        {...field.input}
        onChange={value => field.input.onChange(value)}
        beforeCurrent={false}
        afterCurrent={true}
        placeholder={field.placeholder}
        yearDistance={5}
      />
    </Form.Item>
  );
};

export class AccountGOSAgreementsFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filterValues: PropTypesImmutable.map,
    initialValues: PropTypesImmutable.map,
    counterparty: PropTypes.string.isRequired,
    csvUrl: PropTypes.string.isRequired,
    csvFilename: PropTypes.string.isRequired,
    csvButton: PropTypes.string.isRequired,
  };

  lastUsedFilters = {};

  componentDidMount() {
    this.lastUsedFilters = this.props.initialValues;
  }

  handleCsvExport = () => {
    downloadFileByPostURL(
      this.props.csvUrl,
      this.lastUsedFilters,
      this.props.csvFilename + '.csv'
    );
  };

  render() {
    const { t, reset, handleSubmit, counterparty } = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel
            key="agreements-filter"
            header={t('agreements.filter.panelTitle')}
          >
            <form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={sellingAgreementsFilterFields.STATUS}
                    label={t('agreements.filter.status')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsStatus}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={counterparty}
                    label={t('agreements.filter.counterParty')}
                    component={AutoCompleteAccountsRegCodeField}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={sellingAgreementsFilterFields.FINAL_CONSUMPTION_YEAR}
                    label={t('agreements.filter.finalConsumptionYear')}
                    placeholder={t('agreements.filter.selectYear')}
                    component={yearSelect}
                    hasFeedback={false}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={sellingAgreementsFilterFields.FUEL_TYPE}
                    label={t('agreements.filter.fuelType')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsFuelType}
                    hasFeedback={false}
                    allowed={[
                      fuelType.BIOMETHANE,
                      fuelType.KHG,
                      fuelType.RENEWABLE_ELECTRICITY,
                      fuelType.RENEWABLE_ENERGY,
                    ]}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={sellingAgreementsFilterFields.FUEL_GENERATION}
                    label={t('agreements.filter.fuelGeneration')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.biofuelType}
                    hasFeedback={false}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    name={sellingAgreementsFilterFields.AGREEMENT_TYPE}
                    label={t('agreements.filter.agreementType')}
                    component={ClassificatorField}
                    type={ClassificatorField.types.agreementType}
                    hasFeedback={false}
                  />
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>
                  {t('transactions.filter.clearBtn')}
                </Button>
                <Button
                  onClick={() =>
                    (this.lastUsedFilters = this.props.filterValues)
                  }
                  type="primary"
                  htmlType="submit"
                >
                  {t('transactions.filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <FormActions>
          <Button onClick={() => this.handleCsvExport()}>
            {this.props.csvButton}
          </Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filterValues: getFormValues(props.form)(state),
});

export default connect(mapStateToProps)(
  reduxForm({})(AccountGOSAgreementsFilter)
);
