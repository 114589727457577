import React, { Component } from 'react';
import { Field, Fields, getFormValues, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Collapse, Row } from 'antd';
import { FormActions } from '../../components';
import {
  FORM_ACCOUNT_GOS_AUCTIONS_FILTER,
  tsAuctionsFilter,
} from '../../constants/formAccountGOSAuctionsFilter';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import * as actions from '../../actions/actionCreators/accountGOSAuctionActionCreators';
import { CheckboxField, ClassificatorField, DateRangeField } from '../../atoms';
import './AccountGOSAuctionsFilter.scss';
import { goToTsAuctionHistory, links } from '../../utils/gotoLink';
import { downloadFileByPostURL } from '../../utils/files';
import moment from 'moment';
import { isAdmin } from '../../utils/roles';
import { getRole } from '../../reducers/userReducer';
import { fuelType } from '../../constants/classificators';

const { Panel } = Collapse;

export class AccountGOSAuctionsFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    registerCode: PropTypes.string,
  };

  lastUsedFilters = {};

  render() {
    const { t, handleSubmit, reset, registerCode, isUserAdmin } = this.props;

    return (
      <React.Fragment>
        <Collapse>
          <Panel key="auctions-filter" header={t('filter.title')}>
            <form onSubmit={handleSubmit(onSubmit.bind(this))}>
              <Row gutter={24}>
                <Col span={8}>
                  <Field
                    name={tsAuctionsFilter.FUEL_TYPE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.tsFuelType}
                    label={t('filter.fuelType')}
                    hasFeedback={false}
                    allowed={[
                      fuelType.BIOMETHANE,
                      fuelType.KHG,
                      fuelType.RENEWABLE_ELECTRICITY,
                      fuelType.RENEWABLE_ENERGY,
                    ]}
                  />
                </Col>
                <Col span={8}>
                  <Fields
                    names={[
                      tsAuctionsFilter.END_DATE_START,
                      tsAuctionsFilter.END_DATE_END,
                    ]}
                    component={DateRangeField}
                    allowClear={false}
                    hasFeedback={false}
                    label={t('filter.endDate')}
                    placeholder={[
                      t('filter.dateFromPlaceholder'),
                      t('filter.dateToPlaceholder'),
                    ]}
                    useTime={false}
                  />
                </Col>
                {!isUserAdmin && (
                  <Col span={8}>
                    <Field
                      name={tsAuctionsFilter.IS_SELLER}
                      component={CheckboxField}
                      checkboxText={t('filter.isSeller')}
                      hasFeedback={false}
                      className="filter-checkbox"
                    />
                  </Col>
                )}
              </Row>

              <FormActions>
                <Button
                  onClick={() => {
                    reset();
                    this.lastUsedFilters = {};
                  }}
                >
                  {t('filter.button.clear')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() =>
                    (this.lastUsedFilters = this.props.filterValues || {})
                  }
                >
                  {t('filter.button.submit')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>

        <FormActions>
          <Button
            style={{ float: 'left', marginLeft: 0 }}
            onClick={() =>
              goToTsAuctionHistory(isUserAdmin ? 'admin' : registerCode)
            }
          >
            {t('filter.button.history')}
          </Button>
          <Button
            onClick={() =>
              downloadFileByPostURL(
                links.tsAuctionSearchCsv,
                this.lastUsedFilters,
                t('filter.csv.filename', {
                  timestamp: moment().format('DDMMYYYY_HHmm'),
                }) + '.csv'
              )
            }
          >
            {t('filter.button.csv')}
          </Button>
        </FormActions>
      </React.Fragment>
    );
  }
}

async function onSubmit(values, dispatch) {
  const res = await dispatch(actions.searchAccountGOSAuctions(values));
  dispatch(actions.refreshSearchTimer());
  return res;
}

const getValues = getFormValues(FORM_ACCOUNT_GOS_AUCTIONS_FILTER);

const mapStateToProps = state => ({
  filterValues: getValues(state),
  isUserAdmin: isAdmin(getRole(state)),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_GOS_AUCTIONS_FILTER,
  })(translate(NAMESPACES.auctions)(AccountGOSAuctionsFilter))
);
