import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {invoiceViewPresenterInvoice} from '../../constants/invoice';
import {InvoiceViewItemColComponent} from '../';
import {downloadFileByURLWithoutDownloadAttribute} from '../../utils/files';

class InvoiceViewPresenterFile extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  handleClick = (e, path) => {
    e.preventDefault();
    downloadFileByURLWithoutDownloadAttribute(path);
  };

  render() {
    const {t, data} = this.props;

    return (
      <div className="global-margin-top-16">
        <Row className="global-margin-bottom-10">
          <Col span={24}>
            <InvoiceViewItemColComponent
              itemTitle={t('presenterInvoice.name')}
              itemValue={
                <a
                  href="/presenterInvoice"
                  onClick={e =>
                    this.handleClick(
                      e,
                      data.get(invoiceViewPresenterInvoice.PATH)
                    )
                  }
                >
                  {data.get(invoiceViewPresenterInvoice.NAME)}
                </a>
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceViewPresenterFile;
