import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux-immutable';
import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import {
  createAsyncReducer,
  initialAsyncState,
  toSuccess,
  createAsyncSelector,
  listInitialAsyncState,
} from '../utils/asyncHelpers';
import {
  CONSUMPTION_POINT_ADD_UPDATE,
  CONSUMPTION_POINT_CREATE,
  AVP_POINTS_FETCH,
  CONSUMER_FETCH,
} from '../constants/consumptionPointActionTypes';
import {
  selectPoint,
  toggleCreateForm,
  selectOwner,
  resetFields,
} from '../actions/pureActions/consumptionPointActions';
import { CONSUMER_ADD } from '../constants/consumerAddActionTypes';
import {
  measurement as meteringSchema,
  consumerSchema,
  avpMeteringPointScheme,
} from '../schemas';

const uiInitialState = Map({
  selectedPointId: '0',
  showCreateForm: false,
  selectedOwnerId: null,
});

const point = handleActions(
  {
    ...createAsyncReducer(CONSUMPTION_POINT_ADD_UPDATE),
    [toSuccess(CONSUMPTION_POINT_CREATE)]: (state, { payload }) =>
      state.set('data', payload.result),
    [selectOwner]: state => state.set('data', null),
    [toSuccess('CONSUMPTION_METERING_POINT_UPDATE')]: state =>
      state.set('data', null),
  },
  initialAsyncState
);
const ui = handleActions(
  {
    [toggleCreateForm]: (state, { payload }) =>
      state.set('showCreateForm', payload),
    [selectPoint]: (state, { payload }) =>
      state.set('selectedPointId', payload),
    [toSuccess(CONSUMER_FETCH)]: state =>
      state.merge({
        selectedPointId: '0',
        showCreateForm: false,
      }),
    [toSuccess(CONSUMER_ADD)]: state =>
      state.merge({
        selectedPointId: '0',
        showCreateForm: false,
      }),
    [selectOwner]: (state, { payload }) =>
      state.merge({
        selectedOwnerId: payload,
        selectedPointId: '0',
        showCreateForm: false,
      }),
    [toSuccess(CONSUMPTION_POINT_CREATE)]: (state, { payload }) =>
      state.set('selectedPointId', payload.result),
  },
  uiInitialState
);
const avpPoints = handleActions(
  {
    ...createAsyncReducer(AVP_POINTS_FETCH),
    [selectOwner]: state => state.set('data', List()),
  },
  listInitialAsyncState
);
const consumer = handleActions(
  {
    ...createAsyncReducer(CONSUMER_FETCH),
    ...createAsyncReducer(CONSUMER_ADD),
    [selectOwner]: state => state.set('data', null),
    [resetFields]: () => initialAsyncState,
  },
  initialAsyncState
);

const consumptionPointReducer = combineReducers({
  point,
  ui,
  avpPoints,
  consumer,
});

export const getConsumptionPoint = state =>
  state.getIn(['consumptionPointAdd', 'point']);

export const isCreateFormShow = state =>
  state.getIn(['consumptionPointAdd', 'ui', 'showCreateForm']);

export const getSelectedPointId = state =>
  state.getIn(['consumptionPointAdd', 'ui', 'selectedPointId']);

export const getAvpPoints = state =>
  state.getIn(['consumptionPointAdd', 'avpPoints']);

export const getConsumer = state =>
  state.getIn(['consumptionPointAdd', 'consumer']);

export const getConsumerId = state =>
  state.getIn(['consumptionPointAdd', 'consumer', 'data']);

export const getOwnerId = state =>
  state.getIn(['consumptionPointAdd', 'ui', 'selectedOwnerId']);

export const getConsumerData = createAsyncSelector(consumerSchema, getConsumer);

export const getConsumptionPointData = createAsyncSelector(
  meteringSchema,
  getConsumptionPoint
);

export const getAvpPointsData = createAsyncSelector(
  [avpMeteringPointScheme],
  getAvpPoints
);

export const getAvpAndBmrPointsData = createSelector(
  [getAvpPointsData, getConsumptionPointData],
  (pointsFromAvp, bmrPoint) =>
    bmrPoint.isEmpty() ? pointsFromAvp : pointsFromAvp.push(bmrPoint)
);

export default consumptionPointReducer;
