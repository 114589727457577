import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { accountReportTableFields } from '../../constants/accountTable';
import { formAccountOverviewFilter } from '../../constants/forms/formAccountOverviewFilter';
import { Table } from 'antd';
import {
  translateFuelTypeClassificator,
  translateCleaningTechnologyClassificator,
  translateHydrogenTechnologyTypeClassificator,
  translateHydrogenFuelTypeClassificator,
} from '../../utils/translateHelpers';
import { gas } from '../../constants/classificators';

class AccountReportTable extends Component {
  static propTypes = {
    dataList: PropTypes.array,
    t: PropTypes.func.isRequired,
    fetchAccountReport: PropTypes.func.isRequired,
    filterValues: PropTypes.object.isRequired,
  };

  getColumns = () => {
    const { t, filterValues } = this.props;

    const columns = [
      {
        title: t('table.startBalance'),
        dataIndex: accountReportTableFields.START_BALANCE,
      },
      {
        title: t('table.issuedGO'),
        dataIndex: accountReportTableFields.ISSUED_GO,
      },
      {
        title: t('table.transferredGO'),
        dataIndex: accountReportTableFields.TRANSFERRED_GO,
      },
      {
        title: t('table.enteredGO'),
        dataIndex: accountReportTableFields.ENTERED_SET,
      },
      {
        title: t('table.cancelledGO'),
        dataIndex: accountReportTableFields.CANCELLED_GO,
      },
      {
        title: t('table.expiredGO'),
        dataIndex: accountReportTableFields.EXPIRED_SET,
      },
      {
        title: t('table.endBalance'),
        dataIndex: accountReportTableFields.END_BALANCE,
      },
    ];
    if (filterValues[formAccountOverviewFilter.GAS_TYPE] === gas.BIOMETHANE) {
      if (filterValues[formAccountOverviewFilter.GROUP_BY_FUEL_TYPE]) {
        columns.push({
          title: t('table.fuelType'),
          dataIndex: accountReportTableFields.FUEL_TYPE,
          render: translateFuelTypeClassificator,
        });
      }
      if (filterValues[formAccountOverviewFilter.GROUP_BY_TECHNOLOGY]) {
        columns.push({
          title: t('table.technology'),
          dataIndex: accountReportTableFields.TECHNOLOGY,
          render: translateCleaningTechnologyClassificator,
        });
      }
    }
    if (filterValues[formAccountOverviewFilter.GROUP_BY_PRODUCTION_UNIT]) {
      columns.push({
        title: t('table.eicwCode'),
        dataIndex: accountReportTableFields.EICW_CODE,
      });
    }
    if (filterValues[formAccountOverviewFilter.GAS_TYPE] === gas.HYDROGEN) {
      if (
        filterValues[formAccountOverviewFilter.GROUP_BY_HYDROGEN_TECHNOLOGY]
      ) {
        columns.push({
          title: t('table.hydrogenTechnologyType'),
          dataIndex: accountReportTableFields.TECHNOLOGY,
          render: translateHydrogenTechnologyTypeClassificator,
        });
      }

      if (filterValues[formAccountOverviewFilter.GROUP_BY_HYDROGEN_FUEL_TYPE]) {
        columns.push({
          title: t('table.hydrogenFuelType'),
          dataIndex: accountReportTableFields.HYDROGEN_TYPE,
          render: translateHydrogenFuelTypeClassificator,
        });
      }
    }
    return columns;
  };

  render() {
    const { dataList } = this.props;

    const dataSource = dataList.map((item, index) => ({
      ...item,
      fakeId: index,
    }));

    return (
      <React.Fragment>
        <Table
          rowKey="fakeId"
          dataSource={dataSource}
          columns={this.getColumns()}
          scroll={{ x: 1000 }}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

export default AccountReportTable;
