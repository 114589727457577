import React, { Component } from 'react';
import {
  reduxForm,
  Field,
  Fields,
  formValueSelector,
} from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import PropTypesImmutable from 'react-immutable-proptypes';
import { ClassificatorField, CheckboxField, DateRangeField } from '../../atoms';
import {
  FormActions,
  TableActions,
  AutoCompleteProductionUnitField,
} from '../../components';
import { connect } from 'react-redux';
import { Collapse, Row, Col, Button } from 'antd';
import { FORM_ACCOUNT_REPORT_FILTER } from '../../constants/formKeys';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import { getUserLegalEntity } from '../../reducers/userReducer';
import { formAccountOverviewFilter } from '../../constants/forms/formAccountOverviewFilter';
import moment from 'moment';
import { Map } from 'immutable';
import { FORMAT_DEFAULT } from '../../utils';
import { links } from '../../utils';
import { downloadFileByPostURL } from '../../utils/files';
import { legalEntityFields } from '../../constants/legalEntity';
import { gas } from '../../constants/classificators';
import { change } from 'redux-form';

const { Panel } = Collapse;

const getInitialValues = (accountIds, state) => {
  const legalEntity = getUserLegalEntity(state);
  let gasValue = null;
  if (legalEntity) {
    const isHydrogenManufacturer = legalEntity.get(
      legalEntityFields.HYDROGEN_MANUFACTURER
    );
    const isBiomethaneManufacturer = legalEntity.get(
      legalEntityFields.MANUFACTURER
    );
    const isTrader = legalEntity
      .get('accounts')
      .some(
        account =>
          accountIds.includes(account.get('id') + '') &&
          account.get('accountType') === 'TRADER'
      );
    if (!isTrader) {
      if (isHydrogenManufacturer && !isBiomethaneManufacturer) {
        gasValue = gas.HYDROGEN;
      } else if (!isHydrogenManufacturer && isBiomethaneManufacturer) {
        gasValue = gas.BIOMETHANE;
      }
    }
  }
  let ids = null;
  if (accountIds) {
    ids = accountIds.filter(id => id !== null);
  }
  return Map({
    [formAccountOverviewFilter.BALANCE_START_DATE]: moment()
      .startOf('day')
      .format(FORMAT_DEFAULT),
    [formAccountOverviewFilter.BALANCE_END_DATE]: moment()
      .endOf('day')
      .format(FORMAT_DEFAULT),
    [formAccountOverviewFilter.GAS_TYPE]: gasValue,
    accountIds: ids,
  });
};

export class AccountReportFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    legalEntity: PropTypesImmutable.map.isRequired,
    fetchAccountReport: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    filterValues: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    const { fetchAccountReport, initialValues } = this.props;

    fetchAccountReport(initialValues);
  };

  componentDidUpdate = prevProps => {
    const { fetchAccountReport, accountId, reset } = this.props;

    if (accountId !== prevProps.accountId) {
      reset();
      fetchAccountReport(getInitialValues(accountId));
    }
  };

  handleExportTransactions = () => {
    const { filterValues } = this.props;

    downloadFileByPostURL(
      links.periodicReportExportCsv,
      filterValues,
      'periodic-report.csv'
    );
  };

  render() {
    const {
      t,
      handleSubmit,
      reset,
      accountIds,
      legalEntity,
      gasFieldValue,
      dispatch,
    } = this.props;
    let isTrader = null;
    if (legalEntity) {
      isTrader = legalEntity
        .get('accounts')
        .some(
          account =>
            accountIds.includes(account.get('id') + '') &&
            account.get('accountType') === 'TRADER'
        );
    }

    const handleGasFieldChange = event => {
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.GROUP_BY_TECHNOLOGY,
          false
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.GROUP_BY_HYDROGEN_TECHNOLOGY,
          false
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.GROUP_BY_FUEL_TYPE,
          false
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.GROUP_BY_HYDROGEN_FUEL_TYPE,
          false
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.FUEL_TYPE,
          null
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.HYDROGEN_TECHNOLOGY_TYPE,
          null
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.HYDROGEN_FUEL_TYPE,
          null
        )
      );
      dispatch(
        change(
          'FORM_ACCOUNT_REPORT_FILTER',
          formAccountOverviewFilter.CLEANING_TECHNOLOGY_TYPE,
          null
        )
      );
    };

    return (
      <React.Fragment>
        <Collapse defaultActiveKey={['filters']}>
          <Panel key="filters" header={t('filter.title')}>
            <form onSubmit={handleSubmit}>
              <Row gutter={32}>
                <Col span={16}>
                  <Row gutter={32}>
                    <Col span={24}>
                      <Fields
                        className="date-field"
                        label={t('filter.balanceDateRange.title')}
                        names={[
                          formAccountOverviewFilter.BALANCE_START_DATE,
                          formAccountOverviewFilter.BALANCE_END_DATE,
                        ]}
                        placeholder={[
                          t('filter.balanceDateRange.dateFromPlaceholder'),
                          t('filter.balanceDateRange.dateToPlaceholder'),
                        ]}
                        component={DateRangeField}
                        hasFeedback={false}
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col span={12}>
                      <Field
                        label={t('overview.filter.gas')}
                        name={formAccountOverviewFilter.GAS_TYPE}
                        component={ClassificatorField}
                        type={ClassificatorField.types.gas}
                        hasFeedback={false}
                        onChange={handleGasFieldChange}
                      />
                    </Col>
                    {!isTrader && (
                      <Col span={12}>
                        <Field
                          label={t('overview.filter.productionUnit')}
                          name={formAccountOverviewFilter.PRODUCTION_UNIT_ID}
                          component={AutoCompleteProductionUnitField}
                          hasFeedback={false}
                        />
                      </Col>
                    )}
                  </Row>
                  {gasFieldValue === gas.BIOMETHANE && (
                    <>
                      <Row gutter={32}>
                        <Col span={12}>
                          <Field
                            label={t('overview.filter.fuelType')}
                            name={formAccountOverviewFilter.FUEL_TYPE}
                            component={ClassificatorField}
                            hasFeedback={false}
                            type={ClassificatorField.types.fuelType}
                          />
                        </Col>
                        <Col span={12}>
                          <Field
                            label={t('overview.filter.cleaningTechnologyType')}
                            name={
                              formAccountOverviewFilter.CLEANING_TECHNOLOGY_TYPE
                            }
                            component={ClassificatorField}
                            type={
                              ClassificatorField.types.cleaningTechnologyType
                            }
                            hasFeedback={false}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {gasFieldValue === gas.HYDROGEN && (
                    <>
                      <Row gutter={32}>
                        <Col span={12}>
                          <Field
                            label={t('overview.filter.hydrogenType')}
                            name={formAccountOverviewFilter.HYDROGEN_FUEL_TYPE}
                            component={ClassificatorField}
                            type={ClassificatorField.types.hydrogenType}
                            hasFeedback={false}
                          />
                        </Col>
                        <Col span={12}>
                          <Field
                            label={t('overview.filter.hydrogenTechnologyType')}
                            name={
                              formAccountOverviewFilter.HYDROGEN_TECHNOLOGY_TYPE
                            }
                            component={ClassificatorField}
                            type={
                              ClassificatorField.types.hydrogenTechnologyType
                            }
                            hasFeedback={false}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col span={8}>
                  <span className="page-AccountOverviewPage__groupByLabel">
                    {t('overview.filter.groupBy')}
                  </span>
                  <div className="page-AccountOverviewPage__groupBy">
                    {!legalEntity && (
                      <Field
                        name={
                          formAccountOverviewFilter.GROUP_BY_PRODUCTION_UNIT
                        }
                        component={CheckboxField}
                        hasFeedback={false}
                        checkboxText={t(
                          'overview.filter.groupByProductionUnit'
                        )}
                      />
                    )}
                    {gasFieldValue === gas.BIOMETHANE && (
                      <>
                        <Field
                          name={formAccountOverviewFilter.GROUP_BY_TECHNOLOGY}
                          component={CheckboxField}
                          hasFeedback={false}
                          checkboxText={t('overview.filter.groupByTechnology')}
                        />
                        <Field
                          name={formAccountOverviewFilter.GROUP_BY_FUEL_TYPE}
                          component={CheckboxField}
                          hasFeedback={false}
                          checkboxText={t('overview.filter.groupByFuelType')}
                        />
                      </>
                    )}
                    {gasFieldValue === gas.HYDROGEN && (
                      <>
                        <Field
                          name={
                            formAccountOverviewFilter.GROUP_BY_HYDROGEN_TECHNOLOGY
                          }
                          component={CheckboxField}
                          hasFeedback={false}
                          checkboxText={t(
                            'overview.filter.groupByHydrogenTechnology'
                          )}
                        />
                        <Field
                          name={
                            formAccountOverviewFilter.GROUP_BY_HYDROGEN_FUEL_TYPE
                          }
                          component={CheckboxField}
                          hasFeedback={false}
                          checkboxText={t(
                            'overview.filter.groupByHydrogeFuelType'
                          )}
                        />
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <FormActions>
                <Button onClick={reset}>{t('filter.clearBtn')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('filter.submitBtn')}
                </Button>
              </FormActions>
            </form>
          </Panel>
        </Collapse>
        <TableActions>
          <Button onClick={this.handleExportTransactions}>
            {t('exportCsvBtn')}
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

const filterForm = formValueSelector(FORM_ACCOUNT_REPORT_FILTER);

const mapStateToProps = (state, ownProps) => ({
  legalEntity: getUserLegalEntity(state),
  initialValues: getInitialValues(ownProps.accountIds, state),
  filterValues: filterForm(state, ...Object.values(formAccountOverviewFilter)),
  gasFieldValue: selector(state, formAccountOverviewFilter.GAS_TYPE),
});

const onSubmit = (values, dispatch) =>
  dispatch(actions.fetchAccountReport(values.toJS()));

const mapDispatchToProps = {
  fetchAccountReport: actions.fetchAccountReport,
};
const selector = formValueSelector(FORM_ACCOUNT_REPORT_FILTER);
const AccountReportFilterHoc = connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ACCOUNT_REPORT_FILTER,
    onSubmit,
    enableReinitialize: true,
  })(AccountReportFilter)
);

export default AccountReportFilterHoc;

export const AccountReportFilterTranslated = translate(NAMESPACES.account)(
  AccountReportFilterHoc
);
