import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import {downloadFileByURLWithoutDownloadAttribute} from '../../utils/files';
import {fileFields} from '../../constants/file';

class FileList extends React.Component {
  static propTypes = {
    fileList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  handleClick = (e, path) => {
    e.preventDefault();
    downloadFileByURLWithoutDownloadAttribute(path);
  };

  render() {
    const {t, fileList} = this.props;

    if (fileList.length === 0) {
      return '';
    }

    return (
      <React.Fragment>
        <h3>{t('attachedTitle')}</h3>
        <List
          size="small"
          bordered
          dataSource={fileList}
          renderItem={item => (
            <List.Item>
              <a
                href="/notificationFile"
                onClick={e => this.handleClick(e, item[fileFields.PATH])}
              >
                {item[fileFields.NAME]}
              </a>
            </List.Item>
          )}
        />
      </React.Fragment>
    );
  }
}

export default FileList;
